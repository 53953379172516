import React, { useContext } from "react";
import "./Works.css";
import news from "../../img/news.png"
import info from "../../img/info.png";
import shopping from "../../img/shopping.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import business from "../../img/business.png"
import education from "../../img/education.png"
import {Link} from 'react-scroll'
const Works = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  return (
    <div className="works" id="works">
      {/* left side */}
      <div className="w-left">
        <div className="awesome">
          {/* dark Mode */}
          <span style={{ color: darkMode ? "white" : "" }}>
            Worked On All these
          </span>
          <span>Types Of Websites</span>
          <spane>
          Responsible for designing and coding websites and web pages, many of which blend text with sounds, photos, graphics, and video clips.
            <br />
            It might also imply creating a whole new website or modifying an existing one
            <br />
            Developing skills and expertise in appropriate software/programming languages such as HTML and Javascript
            <br />
            Incorporating functionalities and features into websites
          </spane>
          <Link to="contact" smooth={true} spy={true}>
            <button className="button s-button">Hire Me</button>
          </Link>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        {/* right side */}
      </div>
      <div className="w-right">
        <motion.div
          initial={{ rotate: 180 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 5.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <img src={business} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={education} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={news} alt="" />
          </div>{" "}
          <div className="w-secCircle">
            <img src={info} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={shopping} alt="" />
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle blueCircle"></div>
        <div className="w-backCircle yellowCircle"></div>
      </div>
    </div>
  );
};

export default Works;
