import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import veggievega from "../../img/veggievega.jpg";
import products from "../../img/products.jpg";
import hiyat from "../../img/hiyat.jpg";
import conatctform from "../../img/contactform.jpg";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{ color: darkMode ? "white" : "" }}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <a href="https://hiyat-transport.de/"><img src={hiyat} alt="" /></a>
        </SwiperSlide>
        <SwiperSlide>
        <a href="http://veggie-vega.eu/"><img src={veggievega} alt="" /></a>
        </SwiperSlide>
        <SwiperSlide>
        <a href="https://swaadrestaurant.de/"><img src={conatctform} alt="" /></a>
        </SwiperSlide>
        <SwiperSlide>
        <a href="https://swaadrestaurant.de/"><img src={products} alt="" /></a>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
